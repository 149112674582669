import { useContext, useRef } from 'react';

import BannerContext from 'contexts/Banner';

const useBanner = () => {
  const { isShowing, setIsShowing } = useContext(BannerContext);

  const hideBanner = useRef(() => setIsShowing(false));
  const showBanner = useRef(() => setIsShowing(true));

  return {
    isShowing,
    hideBanner: hideBanner.current,
    showBanner: showBanner.current,
  };
};

export default useBanner;
