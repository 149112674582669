import React from 'react';
import { twMerge } from 'tailwind-merge';

import { RibbonInterface } from '@/types/components';

const Ribbon = ({ text, className }: RibbonInterface): JSX.Element => {
  return (
    <div
      className={twMerge(
        twMerge(
          'py-[5px] px-2 bg-gray-100 rounded-b text-base font-medium text-white w-fit',
          className,
        ),
      )}
    >
      {text}
    </div>
  );
};

export default Ribbon;
