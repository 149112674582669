import React from 'react';
import Image from 'next/image';
import cx from 'classnames';
import Link from 'next/link';

import { CartDrawerInterface } from '@/types/components/Drawer';
import { BUTTON_TYPES } from '@/constant/Button';
import { formatPrice } from '@/utils/money';
import { useCart } from 'hooks';
import { CartAccessoryItemInterface, CartPartItemInterface } from '@/types/api';
import { ADDITIONAL_ROUTES } from '@/data/pages/routes';

import DrawerBase from './Base';
import Button from '../Button';
import { TextColumn } from '../Text';
import { CartCard } from '../Card';
import Spinner from '../Spinner';
import styles from './CartDrawer.module.scss';
import Line from '../Line';
import { resolveTypeId } from '@/hooks/cart';

const CartDrawer = ({ isOpen, onClose }: CartDrawerInterface): JSX.Element => {
  const {
    cartItems,
    cartPrice,
    isLoading,
    cartPriceWithoutShipping,
    cartDiscountedPrice,
  } = useCart();

  const loadingContext = isLoading ? <Spinner size="small" /> : null;

  return (
    <DrawerBase
      isOpen={isOpen}
      onClose={onClose}
      className={cx(
        'min-[651px]:rounded-l max-w-[650px] w-full min-[531px]:max-h-[80%]',
        styles.drawerContainer,
        { 'h-0': !isOpen },
      )}
    >
      <div
        className={cx(
          'bg-brand-100 p-4 max-[530px]:flex flex-row-reverse',
          styles.mobileCloseContainer,
        )}
      >
        <button onClick={onClose}>
          <Image width={24} height={24} src="/icons/x.svg" alt="Close cart" />
        </button>
      </div>
      <div
        className={cx('p-8 max-[530px]:p-2 max-[530px]:pr-4', styles.container)}
      >
        <div
          className={cx(
            'grid gap-x-2 items-center mb-6',
            styles.titleContainer,
          )}
        >
          <button onClick={onClose}>
            <Image width={24} height={24} src="/icons/x.svg" alt="Close cart" />
          </button>
          <div className="brand-100 text-[32px] leading-10 font-bold text-center">
            My cart
          </div>
        </div>
        <Line />
        {!!cartItems.length ? (
          <>
            <div className="my-6">
              <div className="grid gap-y-6">
                {cartItems.map((item) => {
                  const itemId = resolveTypeId(item);

                  return <CartCard {...item} key={itemId} id={itemId} />;
                })}
              </div>
            </div>
            <Line />
            <div className="my-5 grid gap-y-5">
              <TextColumn
                list={[
                  'Price incl. VAT',
                  loadingContext || formatPrice(cartPrice),
                ]}
              />
              <TextColumn
                list={[
                  'Discount',
                  loadingContext || formatPrice(cartDiscountedPrice * -1),
                ]}
              />
            </div>
            <Line />
            <div className="mt-5 mb-6">
              <TextColumn
                className="font-medium text-lg"
                list={[
                  'Total price incl. VAT',
                  loadingContext || formatPrice(cartPriceWithoutShipping),
                ]}
              />
            </div>
            <Link href={ADDITIONAL_ROUTES.checkout.url}>
              <Button
                type={BUTTON_TYPES.FILLED}
                className="w-full"
                loading={isLoading}
              >
                Go to checkout
              </Button>
            </Link>
          </>
        ) : (
          <>
            <div className="my-6 text-center text-brand-100 text-lg font-medium">
              Your cart is currently empty.
            </div>
            <Button
              type={BUTTON_TYPES.FILLED}
              onClick={onClose}
              className="w-full"
            >
              Continue shopping
            </Button>
          </>
        )}
      </div>
    </DrawerBase>
  );
};

export default CartDrawer;
