import React from 'react';
import Image from 'next/image';

import cx from 'classnames';

import styles from './Partnerships.module.scss';

const PARTNER_LOGOS = [
  { logo: 'mastercard', width: 62, height: 48, alt: 'Mastercard logo' },
  { logo: 'visa', width: 84, height: 48, alt: 'Visa logo' },
  { logo: 'stripe', width: 125, height: 28, alt: 'Stripe logo' },
  { logo: 'dhl', width: 198, height: 28, alt: 'DHL logo' },
];

const Partnerships = () => {
  return (
    <div className={cx(styles.partnershipContainer, 'bg-brand-150 flex-1')}>
      {PARTNER_LOGOS.map(({ logo, width, height, alt }) => {
        return (
          <div className={styles.logoContainer} key={logo}>
            <Image
              width={width}
              height={height}
              src={`/images/${logo}.svg`}
              alt={alt}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Partnerships;
