import { IOtherStore } from '@/types/constants';

export const STORES = {
  'Mercedes-Benz': 'Mercedes-Benz',
  Volvo: 'Volvo',
  Toyota: 'Toyota',
  Lexus: 'Lexus',
  Jaguar: 'Jaguar',
  Mazda: 'Mazda',
  Nissan: 'Nissan',
} as const;

export const OTHER_STORES: IOtherStore[] = [
  {
    link: 'https://www.oemstar.co.uk/',
    title: STORES['Mercedes-Benz'],
  },
  {
    link: 'https://oemscan.co.uk/',
    title: STORES.Volvo,
  },
  {
    link: 'https://www.oemoval.co.uk/',
    title: STORES.Toyota,
  },
  {
    link: 'https://www.oemlux.co.uk/',
    title: STORES.Lexus,
  },
  // {
  //   link: 'https://www.oemcat.co.uk/',
  //   title: STORES.Jaguar,
  // },
  // {
  //   link: 'https://www.oemwings.co.uk/',
  //   title: STORES.Mazda,
  // },
  // {
  //   link: 'https://www.oemsun.co.uk/',
  //   title: STORES.Nissan,
  // },
];
