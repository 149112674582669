export const BREAKPOINTS = {
  0: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  320: {
    slidesPerGroup: 1,
    slidesPerView: 1,
  },
  480: {
    slidesPerGroup: 2,
    slidesPerView: 2,
    spaceBetween: 10,
  },
  840: {
    slidesPerGroup: 3,
    slidesPerView: 3,
    spaceBetween: 10,
  },
  1024: {
    slidesPerGroup: 3,
    slidesPerView: 3,
    spaceBetween: 10,
  },
};

export const accessoriesBreakpoints = (length: number) => {
  return {
    0: {
      slidesPerView: 1,
    },
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: length < 2 ? length : 2,
    },
    840: {
      slidesPerView: length < 2 ? length : 2,
    },
    1024: {
      slidesPerView: length < 3 ? length : 3,
    },
    1200: {
      slidesPerView: length < 4 ? length : 4,
    },
  };
};
