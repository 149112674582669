import { useEffect } from 'react';
import ReactModal from 'react-modal';
import Script from 'next/script';

import { GoogleAnalytics } from 'nextjs-google-analytics';
import { hotjar } from 'react-hotjar';

import LayoutWrapper from '@/components/_App/LayoutWrapper';
import { AppPropsWithLayout } from '@/types/pages';

import '../styles/globals.css';

import oemstarLayoutData from '../data/json/oemstar.co.uk/layout.json';
import oemluxLayoutData from '../data/json/oemlux.co.uk/layout.json';
import oemovalLayoutData from '../data/json/oemoval.co.uk/layout.json';
import oemscanLayoutData from '../data/json/oemscan.co.uk/layout.json';
import oemcatLayoutData from '../data/json/oemcat.co.uk/layout.json';
import oemwingsLayoutData from '../data/json/oemwings.co.uk/layout.json';
import oemsunLayoutData from '../data/json/oemsun.co.uk/layout.json';
import App, { AppContext } from 'next/app';

const LAYOUT_DATA_BY_ORIGIN: any = {
  'oemstar.co.uk': oemstarLayoutData,
  'oemlux.co.uk': oemluxLayoutData,
  'oemoval.co.uk': oemovalLayoutData,
  'oemscan.co.uk': oemscanLayoutData,
  'oemcat.co.uk': oemcatLayoutData,
  'oemwings.co.uk': oemwingsLayoutData,
  'oemsun.co.uk': oemsunLayoutData,
};

const HOTJAR_HJID = 3441855;
const HOTJAR_HJSV = 6;

function CustomApp({ Component, pageProps, router }: AppPropsWithLayout) {
  useEffect(() => {
    hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
  }, []);

  ReactModal.setAppElement('#__next');

  const origin = router?.query?.origin as string;
  const data = LAYOUT_DATA_BY_ORIGIN[origin];

  if (!data) {
    throw new Error(
      `Origin ${origin} is given and no data is present in _app. Route: ${router.pathname}`,
    );
  }

  const getLayout =
    Component.getLayout ??
    ((page) => <LayoutWrapper data={data}>{page}</LayoutWrapper>);

  return getLayout(
    <>
      <Script
        id="gdpr"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(){window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=new URLSearchParams(document.location.search),o=e.has("property")?e.get("property"):"website_smart_tag",n=document.createElement("script");n.type="text/javascript",n.src="https://global.ketchcdn.com/web/v2/config/avautopartsltd/".concat(o,"/boot.js"),n.defer=n.async=!0,document.getElementsByTagName("head")[0].appendChild(n)}();`,
        }}
      />
      <GoogleAnalytics
        gaMeasurementId={data.settings.googleAnalyticsId}
        trackPageViews
      />
      <Script id="google-ads">{`
      gtag('config', '${data.settings.googleAdsId}', {
        page_path: window.location.pathname,
      });
    `}</Script>
      <Component {...pageProps} />
    </>,
    data,
  );
}

CustomApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  return appProps;
};

export default CustomApp;
