export default function customFetch(
  url: RequestInfo | URL,
  init: RequestInit = {},
  origin?: string,
) {
  init.headers = {
    ...init.headers,
    'App-Origin': origin ?? globalThis?.location?.host,
  };

  return fetch(url, init);
}
