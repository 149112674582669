import { DetailsResult } from 'use-places-autocomplete';

interface PlaceDetails {
  city: string;
  postalCode: string;
}

function getAddressComponent(component: string, place: any) {
  return place.address_components.find((comp: any) => {
    return comp.types.includes(component);
  });
}

export const getPlaceDetails = (
  placeDetails: string | google.maps.places.PlaceResult,
): PlaceDetails | undefined => {
  if (!placeDetails) return undefined;

  const results = {
    city:
      getAddressComponent('locality', placeDetails)?.long_name ??
      getAddressComponent('postal_town', placeDetails)?.long_name,
    postalCode: getAddressComponent('postal_code', placeDetails)?.long_name,
  };

  return results;
};
