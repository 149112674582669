export const LOCAL_PAGES_PATHS = [
  '/about-us',
  '/cookies-and-privacy-policy',
  '/delivery-policy',
  '/faq',
  '/',
  '/return-policy',
  '/search-by-vin-number',
  '/terms-of-service',
];

export const BE_ACCESSORIES_PATH = `${process.env.NEXT_PUBLIC_API_URL}/accessories`;
export const BE_PARTS_NUMBERS_PATH = `${process.env.NEXT_PUBLIC_API_URL}/parts-numbers`;
export const BE_PARTS_NUMBERS_CURSORS_PATH = `${process.env.NEXT_PUBLIC_API_URL}/parts-numbers-cursors`;

export const BE_ACCESSORY_PATH = `${BE_ACCESSORIES_PATH}/get-accessory?id=`;
export const BE_SUGGESTED_ACCESSORIES_PATH = `${BE_ACCESSORIES_PATH}/get-suggested-accessories`;

export const BE_SEARCH_PART_PATH = `${process.env.NEXT_PUBLIC_API_URL}/search?part_number=`;
export const BE_SEARCH_AFTER_MARKET_PARTS_PATH = `${process.env.NEXT_PUBLIC_API_URL}/search/aftermarket?part_number=`;
