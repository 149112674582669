import React from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

interface BadgeInterface {
  count: number;
  className?: string;
  maxCount?: number;
}

const Badge = ({ count, className, maxCount }: BadgeInterface): JSX.Element => {
  return (
    <div
      className={twMerge(
        cx(
          'bg-red-100 min-w-[19px] h-[19px] text-[10px] leading-3 text-white flex items-center justify-center rounded-full p-1',
          className
        )
      )}
    >
      {maxCount && count > maxCount ? `${maxCount}+` : count}
    </div>
  );
};

export default Badge;
