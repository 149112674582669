import styles from "../components/Spinner/Spinner.module.scss";

export const SPINNER_SIZE = {
  small: {
    spinnerClassName: styles.smallSpinner,
    spanClassName: styles.textSmall,
  },
  medium: {
    spinnerClassName: styles.mediumSpinner,
    spanClassName: styles.textMedium,
  },
  large: {
    spinnerClassName: styles.largeSpinner,
    spanClassName: styles.textLarge,
  },
};
