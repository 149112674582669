import React, { useEffect } from 'react';
import Image from 'next/image';
import Modal from 'react-modal';
import Link from 'next/link';

import { useCart, useScroll } from '@/hooks';
import Button from '@/components/Button';
import { BUTTON_TYPES } from '@/constant/Button';
import { ADDITIONAL_ROUTES } from '@/data/pages/routes';

import styles from './AddedToCartModal.module.scss';
import PriceBox from '../PriceBox';

const AddedToCartModal = (): JSX.Element | null => {
  const { addedItem, setAddedItem } = useCart();
  const { setScrollState } = useScroll();

  useEffect(() => {
    setScrollState(!!addedItem);
  }, [addedItem, setScrollState]);

  if (!addedItem) return null;

  return (
    <Modal
      isOpen={!!addedItem}
      onRequestClose={() => setAddedItem(undefined)}
      className={styles.content}
      contentLabel="Added to cart modal"
      style={{ overlay: { zIndex: 999, backgroundColor: '#04050680' } }}
    >
      <div className={styles.titleContainer}>
        <button
          type="button"
          aria-label="Hide modal"
          onClick={() => setAddedItem(undefined)}
        >
          <Image
            width={24}
            height={24}
            src="/icons/cross_black.svg"
            alt="Hide discount bannder"
          />
        </button>
      </div>
      <div className={styles.checkBox}>
        <Image
          width={48}
          height={48}
          src="/icons/check-gold.svg"
          alt="Success icon"
        />
      </div>
      <div className={styles.messageBox}>
        {addedItem.quantity > 1 ? 'Items' : 'Item'} added to your basket
      </div>
      <div className={styles.separator} />
      <div className={styles.itemContainer}>
        <div className={styles.itemPhoto}>
          <Image
            fill
            loader={({ src, width }) => `${src}?w=${width}`}
            src={addedItem.photo}
            alt={addedItem.title}
            placeholder="blur"
            blurDataURL={addedItem.photo}
          />
        </div>
        <div className={styles.itemTitle}>
          {addedItem.quantity > 1 ? `${addedItem.quantity}x ` : null}
          {addedItem.title}
        </div>
        <div className={styles.itemPrice}>
          <PriceBox
            className="text-right"
            price={addedItem.totalPrice}
            discount={addedItem.totalDiscountedPrice}
            containerClassName="flex-col-reverse"
            priceStyle="w-full text-right"
            discountStyle="w-full text-right"
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          type={BUTTON_TYPES.WIREFRAME}
          onClick={() => setAddedItem(undefined)}
          className="w-full"
        >
          CONTINUE SHOPPING
        </Button>
        <Link href={ADDITIONAL_ROUTES.checkout.url} className="w-full">
          <Button
            type={BUTTON_TYPES.FILLED}
            onClick={() => null}
            className="w-full"
            testAttribute="go-to-checkout"
          >
            GO TO CHECKOUT
          </Button>
        </Link>
      </div>
    </Modal>
  );
};

export default AddedToCartModal;
