import React, { useState } from 'react';

import BannerContext from 'contexts/Banner';
import { ContextProviderBaseInterface } from '@/types/components';

const BannerContextProvider = ({
  children,
}: ContextProviderBaseInterface): JSX.Element => {
  const [isShowing, setIsShowing] = useState(true);

  return (
    <BannerContext.Provider
      value={{
        isShowing,
        setIsShowing,
      }}
    >
      {children}
    </BannerContext.Provider>
  );
};

export default BannerContextProvider;
