import React from 'react';
import cx from 'classnames';

import { TextColumnInterface } from '@/types/components';

const TextColumn = ({ list, className }: TextColumnInterface): JSX.Element => {
  return (
    <div className='justify-between flex'>
      {list.map((text, index) => (
        <span
          key={index}
          className={cx('text-brand-100', className || 'text-base font-light')}
        >
          {text}
        </span>
      ))}
    </div>
  );
};

export default TextColumn;
