import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Script from 'next/script';

import DiscountBanner from '@/components/DiscountBanner';
import useCatalogScript from '@/hooks/useCatalogScript';

import Navbar from './Navbar';
import Footer from './Footer';
import {
  CartContextProvider,
  BannerContextProvider,
} from '../ContextProviders';
import { CartWrapper } from '../Wrappers';

const LayoutWrapper = ({
  children,
  data,
}: {
  children: React.ReactNode;
  data: any;
}) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const [headerOffset, setHeaderOffset] = useState(0);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (headerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (headerRef.current) {
          setHeaderOffset(headerRef.current.offsetHeight);
        }
      });

      resizeObserver.observe(headerRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  // A hack to make default browser scrolling work with a fixed header without holding state of offset.
  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      html.style.scrollPaddingTop = `${headerOffset}px`;
    }
  }, [headerOffset]);

  return (
    <CartContextProvider>
      <BannerContextProvider>
        <CartWrapper>
          <div
            className={cx('flex min-h-screen flex-col justify-between', {
              'overflow-hidden h-screen': isMobileMenuOpen,
            })}
          >
            <div ref={headerRef} className="fixed z-50 w-full">
              <DiscountBanner />
              <Navbar data={data} setMobileMenuOpen={setMobileMenuOpen} />
            </div>
            <main
              style={{
                marginTop: `${headerOffset}px`,
              }}
              className="mb-auto"
            >
              {children}
            </main>
            <Footer data={data} />
          </div>
        </CartWrapper>
        <Script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
        />
      </BannerContextProvider>
    </CartContextProvider>
  );
};

export default LayoutWrapper;
