import React from 'react';
import { twMerge } from 'tailwind-merge';

import { LineInterface } from '@/types/components';

const Line = ({ className }: LineInterface): JSX.Element => {
  return <hr className={twMerge('border-[#C4C4C4]', className)} />;
};

export default Line;
