import React, { Fragment, useEffect, useRef } from 'react';
import cx from 'classnames';

import { DropdownInterface } from '@/types/components';

import { BaseModal } from '../Modal';

const Dropdown = ({
  children,
  className,
  isOpen,
  wrapperClassName,
  headerClassName,
  title,
  items,
  itemClassName,
  listClassName,
  style,
  onClick,
  onItemClick,
  renderModal = false,
}: DropdownInterface): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);

  const itemsContext = (
    <div role="list" className={cx('flex flex-col', listClassName)}>
      {items.map((item, index) => (
        <Fragment key={index}>
          {typeof item === 'string' || typeof item === 'number' ? (
            <button
              type="button"
              className={itemClassName}
              onClick={!!onItemClick ? () => onItemClick(index) : undefined}
            >
              {item}
            </button>
          ) : (
            item
          )}
        </Fragment>
      ))}
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) =>
      !buttonRef.current?.contains(event.target as Node) &&
      !listRef.current?.contains(event.target as Node) &&
      onClick(false);

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <button
        ref={buttonRef}
        className={className}
        style={style}
        onClick={() => onClick(!isOpen)}
      >
        {children}
      </button>
      {isOpen &&
        (renderModal ? (
          <BaseModal
            isOpen
            onClose={() => onClick(false)}
            title={title}
            className={wrapperClassName}
          >
            {itemsContext}
          </BaseModal>
        ) : (
          <div ref={listRef} className={cx('absolute z-10', wrapperClassName)}>
            {!!title && <div className={headerClassName}>{title}</div>}
            {itemsContext}
          </div>
        ))}
    </div>
  );
};

export default Dropdown;
