import React, { useState } from 'react';

import CartContext from 'contexts/Cart';
import { CartAddedItemInterface, CartItemsInterface } from '@/types/api';
import { ContextProviderBaseInterface } from '@/types/components';

const CartContextProvider = ({
  children,
}: ContextProviderBaseInterface): JSX.Element => {
  const [cartItems, setCartItems] = useState<CartItemsInterface[]>([]);
  const [cartPrice, setCartPrice] = useState(0);
  const [cartDiscountedPrice, setCartDiscountedPrice] = useState(0);
  const [cartTotalPayPrice, setCartTotalPayPrice] = useState(0);
  const [cartPriceWithoutShipping, setCartPriceWithoutShipping] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [loadingIds, setLoadingIds] = useState<(number | string)[]>([]);
  const [addedItem, setAddedItem] = useState<CartAddedItemInterface>();
  const [vat, setVat] = useState(0);
  const [vatPrice, setVatPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);

  return (
    <CartContext.Provider
      value={{
        vat,
        vatPrice,
        cartDiscountedPrice,
        cartTotalPayPrice,
        cartItems,
        cartPrice,
        itemCount,
        loadingIds,
        shippingCost,
        setCartItems,
        setCartPrice,
        setCartDiscountedPrice,
        setCartTotalPayPrice,
        setItemCount,
        setLoadingIds,
        addedItem,
        setAddedItem,
        setVat,
        setVatPrice,
        setShippingCost,
        cartPriceWithoutShipping,
        setCartPriceWithoutShipping,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
