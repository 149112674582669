import { useState } from 'react';

const useScroll = () => {
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  const enableScoll = () => {
    setIsScrollDisabled(false);

    document.documentElement.style.overflow = '';
  };

  const disableScoll = () => {
    setIsScrollDisabled(true);

    document.documentElement.style.overflow = 'hidden';
  };

  const setScrollState = (state: boolean) =>
    state ? disableScoll() : enableScoll();

  return {
    isScrollDisabled,
    setScrollState,
  };
};

export default useScroll;
