export const INPUT_RING_STYLE =
  '!ring-brand-100 focus:border-brand-100' as const;

export const INPUT_TEXT_STYLE =
  'placeholder-gray-900 text-base font-normal text-brand-100' as const;

export const LABEL_TEXT_STYLE = 'text-brand-100 text-base font-light' as const;

export const INPUT_BORDER_STYLE = 'bg-white border-[#D9D9D9] rounded' as const;

export const VALIDATION_MESSAGES = {
  email: 'Incorrect format. Email must be of format: example@gmail.com',
  length: (length: number): [number, string] => [
    length,
    `Field must contain ${length} characters`,
  ],
  max: (length: number, name?: string): [number, string] => [
    length,
    `Field ${
      name ? `'${name}' ` : ''
    }can not have more then ${length} characters`,
  ],
  min: (length: number, name?: string): [number, string] => [
    length,
    `Field ${
      name ? `'${name}' ` : ''
    }can not have less then ${length} characters`,
  ],
  minLength: (
    length: number,
    name?: string,
  ): [string, string, (value: number | string) => boolean] => [
    'minLen',
    `Field ${
      name ? `'${name}' ` : ''
    }must be more, or equal, to ${length} characters`,
    (value) =>
      (typeof value === 'number' ? value.toString() : value).length >= length,
  ],
  maxLength: (
    length: number,
    name?: string,
  ): [string, string, (value: number | string) => boolean] => [
    'maxLen',
    `Field ${
      name ? `'${name}' ` : ''
    }must be less, or equal, to ${length} characters`,
    (value) =>
      (typeof value === 'number' ? value.toString() : value).length <= length,
  ],
  setLength: (
    length: number,
    name?: string,
    error?: string,
  ): [string, string, (value?: number | string) => boolean] => [
    'setLen',
    error ?? `Field ${name ? `'${name}' ` : ''}must be ${length} characters`,
    (value) =>
      (typeof value === 'number' ? value.toString() : value)?.length === length,
  ],
  pattern: (example: string, name?: string) =>
    `Field ${name ? `'${name}' ` : ''}value must be in format ${example}`,
  required: (name?: string) =>
    `Field ${name ? `'${name}' ` : ''}must be filled`,
  number: (name?: string) =>
    `Field ${name ? `'${name}' ` : ''}must contain a numeric value`,
  positive: (name?: string) =>
    `Field ${name ? `'${name}' ` : ''}value must be positive`,
  integer: (name?: string) =>
    `Field ${name ? `'${name}' ` : ''}value must be of type integer`,
  booleanChecked: (name?: string) =>
    `Field ${name ? `'${name}' ` : ''}must be checked`,
  validMonth: (name?: string): [string, string, (value: string) => boolean] => [
    'validMonth',
    `Field ${name ? `'${name}' ` : ''}month value can be between 1 and 12`,
    (value) => {
      const months = parseInt(value.trim().split('/')[0]);

      return months <= 12 && months >= 1;
    },
  ],
  validCardExpirationDate: (): [string, string, (value: string) => boolean] => [
    'validCardExpirationDate',
    'The card is expired',
    (value) => {
      const today = new Date();
      const todayYear = parseInt(today.getFullYear().toString().slice(-2));
      const todayMonth = today.getMonth() + 1;
      const split = value.trim().split('/');
      const month = parseInt(split[0]);
      const year = parseInt(split[1]);

      return year >= todayYear && month >= todayMonth;
    },
  ],
  setWorldCount: (
    count: number,
    errorMessage: string,
    splitBy = ' ',
  ): [string, string, (value: string) => boolean] => [
    '2 words',
    errorMessage,
    (value) => value.trim().split(splitBy).length == count,
  ],
  onlyAccept: (type: string, name?: string) =>
    `Field ${name ? `'${name}' ` : ''}only accepts ${type}`,
  invalid: (field: string) => `${field} is invalid`,
};
