import styles from '../components/Button/Button.module.scss';

export enum BUTTON_TYPES {
  WIREFRAME = 'wireframe',
  FILLED = 'filled',
  CART = 'cart',
}

export const BUTTON_TYPE_PROPS = {
  [BUTTON_TYPES.WIREFRAME]: {
    className: styles.typeWireframe,
  },
  [BUTTON_TYPES.FILLED]: {
    className: styles.typeFilled,
  },
  [BUTTON_TYPES.CART]: {
    className: styles.typeCart,
  },
};
