import React from 'react';

import { IBaseModalInterface } from '@/types/components';
import Image from 'next/image';

const Base = ({
  isOpen,
  children,
  title,
  className,
  onClose,
}: IBaseModalInterface): JSX.Element | null => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex bg-black items-center justify-center z-50">
      <div className="absolute top-[28px] px-[30px] flex w-full gap-x-2">
        <div className="w-12" />
        <div className="flex-1 flex justify-center items-center">{title}</div>
        <button
          className="text-gray-600 hover:text-gray-800 h-12 w-12"
          onClick={onClose}
        >
          <Image
            className="p-3"
            width={48}
            height={48}
            src="/icons/cross.svg"
            alt="Close Modal"
          />
        </button>
      </div>
      <div className={className}>{children}</div>
    </div>
  );
};

export default Base;
