import React from 'react';
import Image from 'next/image';
import cx from 'classnames';

import { ButtonInterface } from '@/types/components';
import { BUTTON_TYPE_PROPS } from 'constant';

import Spinner from '../Spinner';
import Badge from '../Badge';

const Button = ({
  type,
  disabled = false,
  children,
  loading = false,
  loadingText,
  className,
  size = 18,
  count,
  testAttribute,
  loaderSize = 'medium',
  onClick,
}: ButtonInterface): JSX.Element => {
  const mainContext =
    type === 'cart' ? (
      <div className="relative">
        <Image
          width={size}
          height={size}
          src="/icons/cart.svg"
          alt="Shopping cart"
        />
        {!!count && (
          <div className="absolute top-[-12px] right-[-10px]">
            <Badge count={count} maxCount={99} />
          </div>
        )}
      </div>
    ) : (
      children
    );

  return (
    <button
      onClick={onClick}
      className={cx(BUTTON_TYPE_PROPS[type].className, className)}
      disabled={disabled || loading}
      data-cy={testAttribute}
    >
      {loading ? (
        <Spinner loadingText={loadingText} size={loaderSize} />
      ) : (
        mainContext
      )}
    </button>
  );
};

export default Button;
