const SUPPORTED_ORIGINS = process.env.SUPPORTED_ORIGINS;

export default function getDomainsPaths() {
  if (!SUPPORTED_ORIGINS) {
    throw Error(`SUPPORTED_ORIGINS must be set, current: ${SUPPORTED_ORIGINS}`);
  }

  const origins = JSON.parse(SUPPORTED_ORIGINS);

  return origins.map((origin: string) => ({
    params: { origin },
  }));
}
