import React from "react";
import cx from "classnames";

import { SpinnerInterface } from "@/types/components";
import { SPINNER_SIZE } from "constant";

import styles from "./Spinner.module.scss";

const Spinner = ({
  size = "small",
  loadingText,
}: SpinnerInterface): JSX.Element => {
  const { spinnerClassName, spanClassName } = SPINNER_SIZE[size];

  return (
    <div className="flex flex-row items-center gap-2 justify-center">
      <div className={cx(styles.loadingSpinner, spinnerClassName)} />
      {loadingText && (
        <span className={cx(styles.text, spanClassName)}>{loadingText}</span>
      )}
    </div>
  );
};

export default Spinner;
