import { RouteInterface } from '@/constant/Route';

export const ROUTES: RouteInterface[] = [
  { url: '/about-us#contact-us', title: 'Contact us', testAttr: 'contact-us' },
  { url: '/', title: 'Home', testAttr: 'home' },
  { url: '/about-us', title: 'About us', testAttr: 'about-us' },
  { url: '/faq', title: 'FAQ', testAttr: 'faq' },
  { url: '/faq#faq-12', title: 'Discount%', testAttr: 'discount' },
];

export const ADDITIONAL_ROUTES = {
  searchByVin: {
    url: '/search-by-vin-number',
    title: 'Search by VIN number',
    description: 'Search for parts providing a VIN number of a vehicle',
  },
  aboutUs: {
    url: '/about-us',
    title: 'About us',
    description: 'Information about us',
  },
  contactUs: {
    url: '/about-us#contact-us',
  },
  faq: {
    url: '/faq',
    title: 'FAQ',
    description: 'Frequently asked questions and answers',
  },
  termsOfService: {
    url: '/terms-of-service',
    title: 'Terms of service',
    description: 'The terms and service page',
  },
  returnPolicy: {
    url: '/return-policy',
    title: 'Return policy',
    description: 'The return policy page',
  },
  deliveryPolicy: {
    url: '/delivery-policy',
    title: 'Delivery policy',
    description: 'The delivery policy page',
  },
  cookiesAndPrivacyPolicy: {
    url: '/cookies-and-privacy-policy',
    title: 'Cookies and privacy policy',
    description: 'The cookies and privacy policy page',
  },
  checkout: {
    url: '/checkout',
    title: 'Checkout',
    description: 'The checkout page',
  },
  accessory: {
    url: '/accessory',
  },
  orderAccept: {
    url: '/order-accept',
    title: 'Order accepted',
    description: 'Order accepted page',
  },
  orderCancel: {
    url: '/order-cancel',
    title: 'Order canceled',
    description: 'Order canceled page',
  },
  orderNotFound: {
    title: 'Order not found',
    description: 'Order not found page',
  },
  catalog: {
    url: '/catalog',
    title: 'Parts Catalog',
    description: 'Catalog to find specific parts',
  },
};
