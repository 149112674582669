import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

import { useCart } from 'hooks';
import { CartCardInterface } from '@/types/components';

import Counter from '../Counter';
import PriceBox from '../PriceBox';
import Ribbon from '../Ribbon';
import styles from './Cart.module.scss';

const CartCard = ({
  photos,
  photo,
  title,
  totalPrice,
  type,
  quantity,
  id,
  totalDiscountedPrice,
  discountPercentage,
  containerClass,
  photoContainerClass,
  counterContainerClass,
  removeBtnContainerClass,
  titleClass,
  testAttribute,
}: CartCardInterface): JSX.Element => {
  const { updateCartItem, removeItemFromCart, isLoading } = useCart();
  const [count, setCount] = useState(quantity);

  const photoUrl = photos?.[0]?.url || photo;

  const handleCountChange = (newCount: number) => {
    const diff = (count - newCount) * -1;

    setCount(newCount);
    updateCartItem(type, diff, id);
  };

  useEffect(() => {
    setCount(quantity);
  }, [quantity]);

  return (
    <div
      className={cx(
        'grid gap-x-[30px] min-[531px]:items-center',
        styles.container,
        containerClass,
      )}
      data-cy={testAttribute}
    >
      <button
        className={cx(styles.removeBtnContainer, removeBtnContainerClass)}
        onClick={() => removeItemFromCart(type, id)}
      >
        <Image
          width={24}
          height={24}
          src="/icons/trash.svg"
          alt="Remove item from cart"
        />
      </button>
      <div
        className={cx('relative', styles.photoContainer, photoContainerClass, {
          'h-auto': !discountPercentage,
        })}
      >
        <Image
          loader={() => `${photoUrl}?w=77&h=77`}
          width={77}
          height={77}
          src={photoUrl as string}
          alt="Cart item photo"
          className="rounded"
        />
        {!!discountPercentage && (
          <Ribbon
            className="absolute bottom-0 w-full text-center py-[2.5px] rounded"
            text={`${discountPercentage}% off`}
          />
        )}
      </div>
      <div
        className={twMerge('text-brand-100 font-medium text-sm', titleClass)}
      >
        {title}
      </div>
      <Counter
        count={count}
        setCount={handleCountChange}
        minCount={1}
        maxCount={51}
        buttonClassName="w-6 h-6 p-0"
        counterClassName="min-w-0 h-6 pl-[14px] pr-[14px]"
        className={cx(styles.counterContainer, counterContainerClass)}
        disabled={isLoading}
      />
      <div className="font-medium text-base text-brand-100">
        <PriceBox
          containerClassName="flex-col-reverse gap-y-1"
          discount={totalDiscountedPrice}
          price={totalPrice}
        />
      </div>
    </div>
  );
};

export default CartCard;
