import cache from 'memory-cache';

export default async function resolvePageDataPath(
  fileName: string,
  origin?: string,
) {
  const cacheKey = `${fileName}/${origin}`;
  const cachedData = cache.get(cacheKey);
  if (cachedData) {
    return cachedData;
  }

  const data = (await import(`@/data/json/${origin}/${fileName}.json`)).default;

  cache.put(cacheKey, data);

  return data;
}
