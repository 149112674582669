import React from 'react';
import cx from 'classnames';

import { PriceBoxInterface } from '@/types/components';
import { formatPrice } from '@/utils/money';
import { twMerge } from 'tailwind-merge';

const PriceBox = ({
  price,
  discount,
  priceStyle,
  discountStyle,
  containerClassName,
  className,
}: PriceBoxInterface): JSX.Element => {
  return (
    <div className={cx(containerClassName, 'flex gap-1 items-end')}>
      {!!discount && (
        <span
          className={twMerge(
            'text-base font-medium text-red-50',
            priceStyle,
            className,
          )}
        >
          {formatPrice(discount)}
        </span>
      )}
      <span
        className={twMerge(
          discount
            ? cx(
                'text-sm font-normal text-gray-600 line-through',
                discountStyle,
                className,
              )
            : cx('text-base font-medium text-brand-100', priceStyle, className),
        )}
      >
        {formatPrice(price)}
      </span>
    </div>
  );
};

export default PriceBox;
