import { useEffect } from 'react';

import { CartInterface } from '@/types/components';
import useCart from '@/hooks/cart';

const Cart = ({ children }: CartInterface): JSX.Element => {
  const { getCartItems } = useCart();

  useEffect(() => {
    getCartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children as JSX.Element;
};

export default Cart;
