import React, { useEffect } from 'react';

import { BackDropInterface } from '@/types/components';
import useScroll from 'hooks/scroll';

const BackDrop = ({
  show,
  noScrollDisable = false,
  onBackdropClick,
}: BackDropInterface): JSX.Element | null => {
  const { setScrollState } = useScroll();

  useEffect(() => {
    if (!noScrollDisable) setScrollState(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!show) return null;

  return (
    <div
      onClick={onBackdropClick}
      className='z-[800] fixed h-screen w-screen bg-black/50'
    />
  );
};

export default BackDrop;
