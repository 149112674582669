import React from 'react';
import Image from 'next/image';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

import { CounterInterface } from '@/types/components';
import Button from '@/components/Button';
import { BUTTON_TYPES } from 'constant';

import styles from './Counter.module.scss';

const Counter = ({
  minCount,
  maxCount,
  count = minCount || 0,
  disabled = false,
  buttonClassName,
  counterClassName,
  className,
  setCount,
}: CounterInterface): JSX.Element => {
  const onIncreate = () => {
    if (maxCount && count === maxCount) return;

    setCount(count + 1);
  };

  const onDecrease = () => {
    if (minCount && count === minCount) return;

    setCount(count - 1);
  };

  return (
    <div className={cx('flex', className)}>
      <Button
        type={BUTTON_TYPES.WIREFRAME}
        onClick={onDecrease}
        className={cx(styles.btn, buttonClassName)}
        disabled={disabled}
      >
        <Image
          className="mx-auto"
          width={11}
          height={1}
          src="/icons/minus.svg"
          alt="Counter decrease"
          style={{
            borderColor: 'red',
          }}
        />
      </Button>
      <div
        className={twMerge(
          cx(
            'min-w-[44px] h-11 flex justify-center items-center text-gray-500 px-1 text-base',
            counterClassName,
          ),
        )}
      >
        {count}
      </div>
      <Button
        type={BUTTON_TYPES.WIREFRAME}
        onClick={onIncreate}
        className={cx(styles.btn, buttonClassName)}
        disabled={disabled}
      >
        <Image
          className="mx-auto"
          width={11}
          height={1}
          src="/icons/plus.svg"
          alt="Counter decrease"
        />
      </Button>
    </div>
  );
};

export default Counter;
