import React from 'react';
import cx from 'classnames';

import { DrawerBaseInterface } from '@/types/components/Drawer';

import BackDrop from '../BackDrop';

const DrawerBase = ({
  isOpen,
  children,
  className,
  fullScreen = false,
  onClose,
}: DrawerBaseInterface) => {
  const context = (
    <div
      className={cx(
        className,
        'bg-white right-0 z-[900] transition-transform ease-in-out duration-300 fixed overflow-y-scroll',
        isOpen ? 'translate-x-0' : 'translate-x-full',
        fullScreen && 'max-h-screen',
      )}
    >
      {children}
    </div>
  );

  return (
    <>
      {fullScreen ? context : <div>{context}</div>}
      <BackDrop show={isOpen} onBackdropClick={onClose} />
    </>
  );
};

export default DrawerBase;
