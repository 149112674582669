import { NextRouter, withRouter } from 'next/router';
import Link from 'next/link';
import React from 'react';
import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

const NavLink = ({
  router,
  href,
  children,
  className,
  muted = false,
  testAttr,
  onClick,
}: {
  router: NextRouter;
  children: React.ReactNode;
  href: string;
  className?: string;
  muted?: boolean;
  testAttr?: string;
  onClick?: () => void;
}) => {
  if (muted) {
    return (
      <Link
        onClick={onClick}
        href={href}
        className={twMerge(
          'text-gray-400 text-base font-normal self-center break-keep',
          className,
        )}
        data-cy={testAttr}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      onClick={onClick}
      href={href}
      className={cx(
        'text-white text-base font-medium self-center break-keep transition-colors',
        {
          'text-gold-active': router.isReady && router.asPath === href,
        },
        className,
      )}
      data-cy={testAttr}
    >
      {children}
    </Link>
  );
};

export default withRouter(NavLink);
