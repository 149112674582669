import React from 'react';
import Image from 'next/image';
import cx from 'classnames';

import Partnerships from '@/components/Partnerships';
import Divider from '@/components/Divider';
import NavLink from '@/components/NavLink';
import { ADDITIONAL_ROUTES } from '@/data/pages/routes';

import styles from './Footer.module.scss';

const Footer = ({ data }: { data: any }) => {
  return (
    <footer className="bg-brand-100">
      <Partnerships />
      <div className={styles.contentPadding}>
        <div className={styles.footerLinks}>
          <div className={styles.leftBlock}>
            <NavLink href="/" aria-label="Home page">
              <Image
                className={styles.logo}
                width={172}
                height={56}
                src={data.logo.src}
                alt={data.logo.alt}
              />
            </NavLink>
            <p className="leading-6 font-normal text-brand-400">
              {data.footerText}
            </p>
          </div>
          <div className={styles.rightBlock}>
            <div className={styles.linkBlock}>
              <div className="text-white text-sm leading-5 font-semibold tracking-wider uppercase">
                Support
              </div>
              <NavLink
                muted
                href={ADDITIONAL_ROUTES.faq.url}
                className={cx(styles.link, 'text-brand-300')}
              >
                FAQ
              </NavLink>
              <NavLink
                muted
                href={ADDITIONAL_ROUTES.deliveryPolicy.url}
                className={cx(styles.link, 'text-brand-300')}
              >
                Delivery policy
              </NavLink>
              <NavLink
                muted
                href={ADDITIONAL_ROUTES.returnPolicy.url}
                className={cx(styles.link, 'text-brand-300')}
              >
                Return policy
              </NavLink>
            </div>
            <div className={styles.linkBlock}>
              <div className="text-white text-sm leading-5 font-semibold tracking-wider uppercase">
                Company
              </div>
              <NavLink
                muted
                href={ADDITIONAL_ROUTES.aboutUs.url}
                className={cx(styles.link, 'text-brand-300')}
              >
                About us
              </NavLink>
              <NavLink
                muted
                href={`${ADDITIONAL_ROUTES.faq.url}#faq-12`}
                className={cx(styles.link, 'text-brand-300')}
              >
                Discount
              </NavLink>
              <NavLink
                muted
                href={`${ADDITIONAL_ROUTES.aboutUs.url}#contact-us`}
                className={cx(styles.link, 'text-brand-300')}
              >
                Contact us
              </NavLink>
              <NavLink
                muted
                href="/parts"
                className={cx(styles.link, 'text-brand-300')}
              >
                Parts
              </NavLink>
            </div>
            <div className={styles.linkBlock}>
              <div className="text-white text-sm leading-5 font-semibold tracking-wider uppercase">
                Legal
              </div>
              <NavLink
                muted
                href={ADDITIONAL_ROUTES.cookiesAndPrivacyPolicy.url}
                className={cx(styles.link, 'text-brand-300')}
              >
                Cookies & Privacy Policy
              </NavLink>
              <NavLink
                muted
                href={ADDITIONAL_ROUTES.termsOfService.url}
                className={cx(styles.link, 'text-brand-300')}
              >
                Terms of service
              </NavLink>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <div className="uppercase leading-5 font-medium mb-2 text-white">
            Reach us
          </div>
          <p className="leading-6 font-normal mb-2 text-brand-400">
            Chat with us:{' '}
            <a
              href="javascript:void(0);"
              onClick={() => {
                // @ts-ignore
                globalThis.zE(globalThis.zE.activate);
              }}
            >
              Open chat
            </a>
          </p>
          <p className="leading-6 font-normal mb-2 text-brand-400">
            Email:{' '}
            <a href="mailto:parts@oemstock.co.uk">parts@oemstock.co.uk</a>
          </p>
          <p className="leading-6 font-normal text-brand-400">
            AV AUTOPARTS LTD Reg 11640035 97 Ridgeway Drive Bromley London BR1
            5DB United Kingdom
          </p>
        </div>
        <Divider />
        <span className="text-base font-normal text-brand-300 w-full">
          The use of copyrighted material, including images and brands, on this
          website is solely intended to identify their respective manufacturers.
          Any mentioned trademarks are the property of their respective owners.
          AV Autoparts LTD declares no connection, endorsement, or affiliation
          with car manufacturers whose products are being offered on this
          website. Car parts catalog and diagrams supplier is
          https://catalogs-parts.com, which is directly responsible for parts
          catalog content, trademarks, copyrights or intellectual property
          related to the catalog.
        </span>
        <Divider />
        <span className="text-base font-normal text-brand-300 text-center w-full">
          &copy; {new Date().getFullYear()} All rights reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
