export default function shouldShowCatalog(
  origin: string | string[] | undefined,
) {
  if (Array.isArray(origin) || typeof origin === 'undefined') {
    return false;
  }
  return [
    'oemstar.co.uk',
    'oemoval.co.uk',
    'oemlux.co.uk',
    'oemsun.co.uk',
  ].includes(origin);
}
