import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Divide as Hamburger } from 'hamburger-react';
import { useBanner, useCart, useWindowSize } from 'hooks';

import NavLink from '@/components/NavLink';
import PartSearchBar from '@/components/PartSearchBar';
import { ADDITIONAL_ROUTES, ROUTES } from '@/data/pages/routes';
import { shouldShowCatalog } from '@/utils';
import { StateHandler } from '@/types/general';
import { BUTTON_TYPES } from '@/constant/Button';
import { OTHER_STORES } from '@/constant/Store';

import styles from './Navbar.module.scss';
import Button from '../Button';
import { CartDrawer } from '../Drawer';
import AddedToCartModal from '../AddedToCartModal';
import Spinner from '../Spinner';
import Dropdown from '../Dropdown';

const Navbar = ({
  setMobileMenuOpen,
  data,
}: {
  setMobileMenuOpen: StateHandler<boolean>;
  data: any;
}) => {
  const router = useRouter();

  const { itemCount } = useCart();
  const { hideBanner, showBanner } = useBanner();

  const [isOpen, setOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSearchError, setIsSearchError] = useState(false);
  const [innerWidth] = useWindowSize();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDrawer = () => setIsCartOpen((oldState) => !oldState);

  const closeMobileMenu = () => {
    setOpen(false);
    setMobileMenuOpen(false);
  };

  const isLaptop = innerWidth > 910;
  const isMobile = innerWidth < 768;
  const scrollY = typeof window === 'undefined' ? 0 : window.scrollY;

  useEffect(() => {
    const show = () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      showBanner();
    };

    if (isLaptop) show();
    else {
      if (isOpen) {
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}px`;
        document.body.style.width = `${innerWidth}px`;
        hideBanner();
      } else show();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLaptop, isOpen, innerWidth, scrollY]);

  useEffect(() => {
    setMobileMenuOpen((isDropdownOpen && isMobile) || isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <>
      <header>
        <div
          className={cx(
            styles.header,
            'flex items-center justify-between flex-row bg-brand-100 z-10 relative',
          )}
        >
          <div className="flex gap-x-1 bg-brand-100 shadow-[0_4px_6px_-4px_rgba(55,55,55,0.20),0_10px_15px_-3px_rgba(55,_55,_55,_0.2)]">
            <Dropdown
              renderModal={isMobile}
              isOpen={isDropdownOpen}
              onClick={(state) => {
                setIsDropdownOpen(state);

                if (isMobile) setMobileMenuOpen(state);
              }}
              className="bg-brown-200 w-[32px] h-[72px] mr-[12px] rounded-l flex justify-center items-center relative before:content-[''] before:absolute before:top-0 before:bottom-0 before:right-[-6px] before:bg-brown-200 before:w-6/12 before:skew-x-[-10deg]"
              wrapperClassName={
                isMobile
                  ? 'w-[80%]'
                  : 'w-[288px] bg-brand-100 mt-2 rounded-lg border-[3px] border-[#585757] p-8 shadow-[0_0_0_1px_rgba(0,0,0,0.05),0_4px_6px_-2px_rgba(0,0,0,0.05),0_10px_15px_-3px_rgba(0,0,0,0.10)]'
              }
              title={
                <>
                  <div
                    className={cx(
                      isMobile ? 'text-2xl text-center' : 'text-base',
                      'text-white font-medium',
                    )}
                  >
                    Visit our other websites
                  </div>
                  {!isMobile && (
                    <div className="w-[36px] my-4">
                      <hr className="border-white" />
                    </div>
                  )}
                </>
              }
              listClassName={isMobile ? 'gap-y-[24px]' : 'gap-y-[12px]'}
              items={OTHER_STORES.map(({ link, title }, index) => {
                const isSameStore = data.name === title;

                return (
                  <a
                    key={index}
                    href={link}
                    className={cx(
                      isSameStore ? 'text-brand-300' : 'text-[#666666]',
                      isMobile ? 'text-center text-2xl' : 'text-sm',
                    )}
                  >
                    Genuine{isMobile ? <br /> : ' '}
                    {isSameStore ? (
                      <span className="text-brown-200">{title}</span>
                    ) : (
                      title
                    )}{' '}
                    parts{!isSameStore && '*'}
                  </a>
                );
              })}
            >
              <Image
                width={16}
                height={16}
                src="/icons/arrowUp.svg"
                alt="arrow up icon"
                className={cx('transition-[transform] ease-in-out', {
                  'rotate-180': !isDropdownOpen,
                })}
                style={{
                  filter: 'brightness(0) invert(1)',
                }}
              />
            </Dropdown>
            <NavLink href="/" aria-label="Home page" testAttr="header-logo">
              <Image
                className={cx(styles.logo, 'w-auto h-[48px]')}
                width={134}
                height={48}
                src={data.logo.src}
                alt={data.logo.alt}
              />
            </NavLink>
          </div>

          <PartSearchBar
            data={data}
            isLoading={searchLoading}
            onError={setIsSearchError}
            onLoading={setSearchLoading}
          />

          <div className={styles.linkContainer}>
            <>
              {ROUTES.map(({ url, title, icon, alt, testAttr }) => (
                <NavLink
                  href={url}
                  key={url}
                  className="hover:text-gold-hover active:text-gold-active"
                  testAttr={testAttr}
                >
                  {title ? (
                    title
                  ) : (
                    <Image
                      width={18}
                      height={18}
                      src={`/icons/${icon}`}
                      alt={alt ?? 'icon'}
                    />
                  )}
                </NavLink>
              ))}
              <Button
                type={BUTTON_TYPES.CART}
                onClick={toggleDrawer}
                count={itemCount}
              />
            </>
          </div>
          <Button
            type={BUTTON_TYPES.CART}
            onClick={() => {
              toggleDrawer();
            }}
            className={cx('mx-auto p-[10px]', styles.mobileCart)}
            size={36}
            count={itemCount}
          />
          <div className={styles.hamburgerTrigger}>
            <Hamburger
              toggled={isOpen}
              toggle={(state) => {
                setOpen(state);
                setMobileMenuOpen(state);
              }}
              color="#fff"
              label="Menu button"
            />
          </div>
          <div className={cx(styles.mobileMenu, { hidden: !isOpen })}>
            <div className={styles.mobileMenuContainer}>
              {ROUTES.map(({ url, title, icon, alt }) => (
                <NavLink
                  onClick={closeMobileMenu}
                  href={url}
                  key={url}
                  className={cx(
                    styles.mobileLink,
                    'text-3xl hover:text-gold-hover active:text-gold-active',
                  )}
                >
                  {title ? (
                    title
                  ) : (
                    <Image
                      width={32}
                      height={32}
                      src={`/icons/${icon}`}
                      alt={alt ?? 'OEMStar icon'}
                    />
                  )}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
        <div
          className={cx(
            styles.underHeaderWrapper,
            {
              visible: searchLoading || isSearchError,
            },
            'transition-all z-0 relative top-0',
            !searchLoading && !isSearchError && '-top-[150px]',
          )}
        >
          <div className={cx(styles.underHeader, 'flex justify-center')}>
            {searchLoading ? (
              <div className="items-center flex min-h-[100px]">
                <Spinner size="medium" />
              </div>
            ) : (
              !!isSearchError && (
                <div className="text-white font-normal text-base text-left w-full">
                  <div className="flex justify-between">
                    <div className="text-2xl">No search results found</div>
                    <button
                      onClick={() => {
                        setIsSearchError(false);
                      }}
                      type="button"
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        className="h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <ul className="mt-4 list-disc ml-3">
                    {shouldShowCatalog(router.query.origin) ? (
                      <li>
                        Find the correct part number by using our{' '}
                        <Link href="/#search-catalog" className="underline">
                          catalog
                        </Link>
                      </li>
                    ) : (
                      <li>
                        Make sure the part number is correct, by using our{' '}
                        <Link
                          href={ADDITIONAL_ROUTES.searchByVin.url}
                          className="underline"
                        >
                          FAQ
                        </Link>
                      </li>
                    )}
                    <li className="my-2">
                      Contact us for assistance at{' '}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        className="underline"
                        href="mailto:parts@oemstock.co.uk"
                      >
                        parts@oemstock.co.uk
                      </a>
                    </li>
                  </ul>
                </div>
              )
            )}
          </div>
        </div>
      </header>
      <CartDrawer isOpen={isCartOpen} onClose={toggleDrawer} />
      <AddedToCartModal />
    </>
  );
};

export default Navbar;
