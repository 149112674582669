import React, { useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import cx from 'classnames';

import { DiscountBannerInterface } from '@/types/components';
import useBanner from '@/hooks/banner';

const DiscountBanner = ({ className }: DiscountBannerInterface) => {
  const { isShowing } = useBanner();
  const bannerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={twMerge(
        'sticky top-0 bg-brand-200 text-white z-50 py-3 text-center flex flex-col lg:block px-12',
        className,
        cx({ hidden: !isShowing }),
      )}
      ref={bannerRef}
      data-cy="banner"
    >
      <span className="text-sm leading-6 font-medium">
        Orders over 500 GBP -2%, over 1000 GBP -5% discount
      </span>
      <Link
        href="/faq#faq-12"
        className="ml-2 font-bold text-base"
        data-cy="learn-more"
      >
        Learn more →
      </Link>
      <button
        type="button"
        aria-label="Hide discount banner"
        onClick={() => bannerRef?.current?.remove()}
        data-cy="close-button"
      >
        <Image
          className="absolute right-5 top-8 lg:right-5 lg:top-5"
          width={12}
          height={12}
          src="/icons/cross.svg"
          alt="Hide discount bannder"
        />
      </button>
    </div>
  );
};

export default DiscountBanner;
