import { createContext, Dispatch, SetStateAction } from 'react';

interface initialValuesInterface {
  isShowing: boolean;
  setIsShowing: Dispatch<SetStateAction<boolean>>;
}

const initialValues: initialValuesInterface = {
  isShowing: true,
  setIsShowing: () => {},
};

const BannerContext = createContext<initialValuesInterface>(initialValues);

export default BannerContext;
