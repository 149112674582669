import { useContext, useRef } from 'react';
import { event } from 'nextjs-google-analytics';
import { fetch } from '@/utils';

import {
  CartItemsInterface,
  CartTypes,
  ResponseCartInterface,
} from '@/types/api';
import CartContext from 'contexts/Cart';

import { getStorageItem, setStorageItem } from '../utils';

export const resolveTypeId = (item: CartItemsInterface) => {
  if (item.type === 'accessory') {
    return item.accessoryId;
  }

  if (item.type === 'part') {
    return item.partNumber;
  }

  return `${item.partNumber}@${item.manufacturerCode}`;
};

const useCart = () => {
  const {
    loadingIds,
    cartItems,
    cartPrice,
    cartDiscountedPrice,
    cartTotalPayPrice,
    itemCount,
    vatPrice,
    vat,
    shippingCost,
    setCartItems,
    setCartPrice,
    setCartDiscountedPrice,
    setCartTotalPayPrice,
    setItemCount,
    setLoadingIds,
    addedItem,
    setAddedItem,
    setVat,
    setVatPrice,
    setShippingCost,
    cartPriceWithoutShipping,
    setCartPriceWithoutShipping,
  } = useContext(CartContext);

  const getCartKey = useRef(() => getStorageItem('cartKey') || 'noKey');

  const updateState = ({
    cartKey,
    items,
    totalPrice,
    payPrice,
    totalDiscountedPrice,
    itemCount,
    vat,
    vatPrice,
    shippingCost,
    payPriceWithoutDetails,
  }: ResponseCartInterface) => {
    setStorageItem('cartKey', cartKey);
    setCartItems(items);
    setCartPrice(totalPrice);
    setCartTotalPayPrice(payPrice);
    setCartDiscountedPrice(totalDiscountedPrice);
    setItemCount(itemCount);
    setVat(vat);
    setVatPrice(vatPrice);
    setShippingCost(shippingCost);
    setCartPriceWithoutShipping(payPriceWithoutDetails);
  };

  const handeAddItemToCart = async ({
    type,
    quantity,
    id,
  }: {
    type: CartTypes;
    quantity: number;
    id: number | string;
  }) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        [{
          accessory: 'accessoryId',
          part: 'partNumber',
          afterMarketPart: 'manufacturerCode',
        }[type]]: id,
        cartKey: getCartKey.current(),
        quantity,
      }),
    };

    await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/cart/addItem`,
      requestOptions,
    )
      .then((data) => data.json())
      .then((data) => {
        if (data?.cart && data?.addedItem) {
          const itemId = {
            accessory: data.addedItem.accessoryId,
            part: data.addedItem.partNumber,
            afterMarketPart: data.addedItem.manufacturerCode,
          }[type];

          setAddedItem(data.addedItem);
          event('add_to_cart', {
            currency: 'GBP',
            value: data.addedItem.totalPrice,
            items: [
              {
                item_category: data.addedItem.type,
                item_id: itemId,
                item_name: data.addedItem.title,
                discount:
                  data.addedItem.price - data.addedItem.totalDiscountedPrice,
                price: data.addedItem.price,
                quantity: data.addedItem.quantity,
              },
            ],
          });

          updateState(data.cart);
        }
      })
      .finally(() =>
        setLoadingIds((oldValues) =>
          [...oldValues].filter((val) => val !== id),
        ),
      );
  };

  const handleUpdateCartItem = async ({
    type,
    quantity,
    id,
  }: {
    type: CartTypes;
    quantity: number;
    id: number | string;
  }) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        [{
          accessory: 'accessoryId',
          part: 'partNumber',
          afterMarketPart: 'manufacturerCode',
        }[type]]: id,
        cartKey: getCartKey.current(),
        quantity,
      }),
    };

    await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/cart/updateItem`,
      requestOptions,
    )
      .then((data) => data.json())
      .then((data) => {
        if (data?.cart) {
          updateState(data.cart);
        }
      })
      .finally(() =>
        setLoadingIds((oldValues) =>
          [...oldValues].filter((val) => val !== id),
        ),
      );
  };

  const handleRemoveItemFromCart = async (
    type: CartTypes,
    id: number | string,
  ) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        [{
          accessory: 'accessoryId',
          part: 'partNumber',
          afterMarketPart: 'manufacturerCode',
        }[type]]: id,
        cartKey: getCartKey.current(),
      }),
    };

    await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/cart/removeItem`,
      requestOptions,
    )
      .then((data) => data.json())
      .then((data) => {
        if (data?.cart) {
          const removedItem = cartItems.find(
            (item) => resolveTypeId(item) === id,
          );

          if (removedItem) {
            event('remove_from_cart', {
              currency: 'GBP',
              value: removedItem.totalPrice,
              items: [
                {
                  item_category: removedItem.type,
                  item_id: resolveTypeId(removedItem),
                  item_name: removedItem.title,
                  discount:
                    removedItem.price - removedItem.totalDiscountedPrice,
                  price: removedItem.price,
                  quantity: removedItem.quantity,
                },
              ],
            });
          }

          updateState(data.cart);
        }
      })
      .finally(() =>
        setLoadingIds((oldValues) =>
          [...oldValues].filter((val) => val !== id),
        ),
      );
  };

  const getCartItems = useRef(async () => {
    const resCartItems = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/cart?cartKey=${getCartKey.current()}`,
    );

    const { cart } = await resCartItems.json();

    updateState(cart);
  });

  const addItemToCart = useRef(
    (type: CartTypes, quantity: number, id: number | string) => {
      setLoadingIds((oldValue) => [...oldValue, id]);
      handeAddItemToCart({ type, quantity, id });
    },
  );

  const updateCartItem = useRef(
    (type: CartTypes, quantity: number, id: number | string) => {
      setLoadingIds((oldValue) => [...oldValue, id]);
      handleUpdateCartItem({ type, quantity, id });
    },
  );

  const removeItemFromCart = useRef((type: CartTypes, id: number | string) => {
    setLoadingIds((oldValue) => [...oldValue, id]);
    handleRemoveItemFromCart(type, id);
  });

  return {
    loadingIds,
    itemCount,
    cartItems,
    cartPrice,
    isLoading: !!loadingIds.length,
    cartDiscountedPrice,
    cartTotalPayPrice,
    vat,
    vatPrice,
    shippingCost,
    getCartItems: getCartItems.current,
    addItemToCart: addItemToCart.current,
    removeItemFromCart: removeItemFromCart.current,
    addedItem,
    setAddedItem,
    updateCartItem: updateCartItem.current,
    getCartKey: getCartKey.current,
    cartPriceWithoutShipping,
  };
};

export default useCart;
