import { LocalStorageKeys } from '@/types/utils';

export const canAccessStorage = () => typeof window !== 'undefined';

export const setStorageItem = (key: LocalStorageKeys, value: string) => {
  if (canAccessStorage()) localStorage.setItem(key, value);
};

export const getStorageItem = (key: LocalStorageKeys) => {
  if (canAccessStorage()) return localStorage.getItem(key);
};

export const removeStorageItem = (key: LocalStorageKeys) => {
  if (canAccessStorage()) localStorage.removeItem(key);
};

export const clearStorage = () => {
  if (canAccessStorage()) localStorage.clear();
};
