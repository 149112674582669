import { createContext, Dispatch, SetStateAction } from 'react';

import { CartAddedItemInterface, CartItemsInterface } from '@/types/api';

interface initialValuesInterface {
  cartItems: CartItemsInterface[];
  cartPrice: number;
  cartDiscountedPrice: number;
  cartTotalPayPrice: number;
  cartPriceWithoutShipping: number;
  itemCount: number;
  vat: number;
  vatPrice: number;
  shippingCost: number;
  loadingIds: (number | string)[];
  setCartItems: Dispatch<SetStateAction<CartItemsInterface[]>>;
  setCartPrice: Dispatch<SetStateAction<number>>;
  setVat: Dispatch<SetStateAction<number>>;
  setVatPrice: Dispatch<SetStateAction<number>>;
  setShippingCost: Dispatch<SetStateAction<number>>;
  setCartDiscountedPrice: Dispatch<SetStateAction<number>>;
  setCartTotalPayPrice: Dispatch<SetStateAction<number>>;
  setCartPriceWithoutShipping: Dispatch<SetStateAction<number>>;
  setItemCount: Dispatch<SetStateAction<number>>;
  setLoadingIds: Dispatch<SetStateAction<(number | string)[]>>;
  addedItem?: CartAddedItemInterface;
  setAddedItem: Dispatch<SetStateAction<CartAddedItemInterface | undefined>>;
}

const initialValues: initialValuesInterface = {
  cartItems: [],
  cartPrice: 0,
  cartDiscountedPrice: 0,
  cartTotalPayPrice: 0,
  cartPriceWithoutShipping: 0,
  itemCount: 0,
  vat: 0,
  vatPrice: 0,
  shippingCost: 0,
  loadingIds: [],
  addedItem: undefined,
  setCartItems: () => {},
  setCartPrice: () => {},
  setCartDiscountedPrice: () => {},
  setCartTotalPayPrice: () => {},
  setCartPriceWithoutShipping: () => {},
  setItemCount: () => {},
  setLoadingIds: () => {},
  setAddedItem: () => {},
  setVatPrice: () => {},
  setShippingCost: () => {},
  setVat: () => {},
};

const CartContext = createContext<initialValuesInterface>(initialValues);

export default CartContext;
