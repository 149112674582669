import { Notify } from 'notiflix/build/notiflix-notify-aio';

export const validatePartNumber = (partNumber?: string) => {
  if (!partNumber || partNumber.length < 3 || partNumber.length > 20) {
    Notify.failure(
      'Part number should be between 3 and 20 symbols.',
      undefined,
      {
        position: 'left-bottom',
      },
    );
    return false;
  }
  return true;
};
