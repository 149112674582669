import React, { FormEvent, useEffect, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { event } from 'nextjs-google-analytics';

import { fetch, shouldShowCatalog } from '@/utils';
import { validatePartNumber } from '@/utils/validations';
import { PartSearchBarInterface } from '@/types/components';

import styles from './PartSearchBar.module.scss';

const PartSearchBar = ({
  isLoading,
  onError,
  onLoading,
  data,
}: PartSearchBarInterface) => {
  const router = useRouter();

  const [partNumber, setPartNumber] = useState('');

  useEffect(() => {
    const handleStop = () => {
      onLoading(false);
      // setPartNumber('');
      onError(false);
    };

    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, onLoading, setPartNumber]);

  const search = async () => {
    fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/search?part_number=${partNumber}`,
      {
        headers: { accept: 'application/json' },
      },
    )
      .then((resp) => {
        if (!resp.ok) throw resp;

        event('SearchPart', {
          category: 'Top',
          label: 'Success',
        });

        return resp.json();
      })
      .then((part) => {
        router.push({
          pathname: `/part/${part.partNumber}`,
        });

        onError(false);
      })
      .catch((error) => {
        if (partNumber.length === 17) {
          router.push({
            query: {
              vin: partNumber,
            },
            pathname: '/catalog',
          });
        } else {
          error?.json()?.then(() => onError(true));

          event('SearchPart', {
            category: 'Top',
            label: 'Fail',
          });

          onLoading(false);
        }
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLoading && !validatePartNumber(partNumber)) return;
    if (validatePartNumber(partNumber)) {
      onLoading(true);
      search();
    }
  };

  return (
    <div className={cx(styles.searchBox, 'relative flex-1')}>
      {shouldShowCatalog(router.query.origin) && (
        <p
          className="text-white underline mt-2 font-normal text-sm absolute bottom-[-25px] cursor-pointer"
          onClick={() => {
            router.push('/#search-part');
          }}
        >
          Don’t have a part number? Click here
        </p>
      )}
      <form className="w-full" onSubmit={handleSubmit}>
        <input
          value={partNumber}
          onChange={(e) => setPartNumber(e.target.value)}
          type="text"
          maxLength={20}
          name="part-number-search"
          id="part-number-search"
          className={cx(
            styles.searchInput,
            'block w-full bg-brand-50 rounded text-base font-normal pr-[15%] transition-colors focus:ring-gold-100 hover:ring-[0.5px] hover:ring-gold-hover',
          )}
          disabled={isLoading}
          placeholder={data.searchPlaceholder}
          data-cy="search-bar"
        />

        <button
          className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3 justify-between"
          type="submit"
          disabled={isLoading}
          data-cy="search-button"
        >
          <Image
            className={styles.logo}
            width={21}
            height={21}
            src="/icons/search.svg"
            alt="Information icon"
          />
        </button>
      </form>
    </div>
  );
};

export default PartSearchBar;
